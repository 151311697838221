import styled from 'styled-components';

export const GtTable = styled.table<{
  customStyle?: string;
}>`
  display: grid;
  width: 100%;
  height: 100%;

  border: solid 1px #dcdfe2;
  ${({ customStyle }) => customStyle}

  // HOLD CIRCLE
  .hold-circle {
    width: 20px;
    height: 20px;
    background-color: #4e46e9;
    border-radius: 100%;
    border: 1px solid rgba(220, 223, 226, 0.6);
    cursor: pointer;

    margin-left: auto;
    margin-right: auto;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .centered-hold-column {
    width: 100%;
    text-align: center;
  }

  .unhold-circle {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid rgba(220, 223, 226, 0.6);
    cursor: pointer;

    margin-left: auto;
    margin-right: auto;

    &:hover {
      opacity: 0.6;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .gt-clock-cell > .gt-editable-input-with-clock {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .gt-input > .gt-editable-input-with-clock > input {
    background-color: transparent !important;
  }

  .gt-clock-cell span.gt-clock {
    display: none;
  }

  .gt-clock-cell.active span.gt-clock {
  }

  .gt-editable-input-with-clock span.gt-clock {
    position: absolute;
    top: 4px;
    right: 6px;
    display: inline-block;

    gap: 3px;

    cursor: pointer;
  }

  .gt-editable-input-with-clock .gt-clock-input {
    position: absolute;
    top: 3px;
    right: 4px;
    width: calc(100% - 22px);
  }

  .slick-cell .gt-unit-input {
    font-family: IBM Plex Mono, monospace !important;
    font-size: 13px !important;
    display: table-cell;
    text-align: right;
    padding-right: 7px;
    width: calc(100% - 20px);
    background-color: transparent;
    margin-top: -3px;
  }

  .gt-clock-input {
    font-family: IBM Plex Mono, monospace !important;
    font-size: 13px !important;
    display: table-cell;
    text-align: right;
    padding-right: 20px;
    color: rgba(15, 18, 32, 1);
  }

  .gt-input-type-icon {
    padding-top: 3px;
    padding-bottom: 3px;
    z-index: 2;

    display: flex;
    justify-content: center;

    > span {
      padding:  0px 6px;
      font-weight: 600;
      font-size: 11px;
      border-radius: 4px;
    }

    > span.manual {
      border: 1px solid rgba(62, 66, 85, 0.65);;
      color: rgba(62, 66, 85, 0.65);;
    }

    > span.auto {
      color: #006420;
      background-color: #e0ffe2;
      border: 1px solid #006420;
    }
  }

  .gt-triggers-column {
    padding-top: 3px;
    padding-bottom: 3px;

    display: flex;
    justify-content: center;

    > .gt-triggers-amount {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 18px;
      border-radius: 44px;
      background-color: #e6e5ff;
      box-shadow: -1px 1px 1px 0px #0101014D;

      font-family: IBM Plex Mono;
      font-size: 12px;
      font-weight: 700;
      color: #5850ec;

      cursor: pointer;

      &._news {
        background-color: #5850ec;
        color: #ffffff;
      }
    }

    &._disabled {
      cursor: not-allowed;
    }
  }

  .slick-cell.fixed {
    font-family: IBM Plex Mono, monospace !important;
    font-size: 13px !important;
    padding-top: 5px;
    padding-left: 10px;

    &._passage {
      display: flex;
      justify-content: end;
      padding-right: 22px;
    }
  }

  .slick-cell._invalid {
    background-color: rgba(237, 32, 36, 0.1) !important;
    color: rgba(237, 32, 36, 1);

    input {
      width: 100%;
      background-color: transparent;
    }

    z-index: 3;
    &._zindex-4 {
      z-index: 4;
    }
    input {
      width: 100%;
      background-color: transparent;
    }
    
    > div.toltip_wrapper {
      position: relative;
      > div {
        display: none;
        position: absolute;
        right: -10px;
        top: 0;
        > span.toltip {
          position: fixed;
          display: block;
          background-color: #ffffff;
          padding: 6px 11px;
          box-shadow: 0px 2px 4px rgba(51, 57, 78, 0.1);
          border-radius: 6px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: rgba(15, 18, 32, 0.6);
        }
      }
    }
    &:hover > div > div {
      display: block;
    }

    > .toltip_wrapper {
      .toltip {
        display: none;
      }
    }
  }

  .slick-grid-menu-button {
    background: #FFF;

    height: 100%;
    width: 20px;

    margin: 0;
  }

  .slick-cell.gt-input.disabled {
    cursor: not-allowed;
  }

  .slick-cell.gt-input > input {
    border: none;
    box-shadow: none;
  }

  .slick-cell .observer.hold-circle {
    cursor: not-allowed;
  }

  .slick-cell.gt-cell.disabled {
    cursor: not-allowed;
  }

  .slick-cell > input {
    width: 100%;
    height: 20px !important;
    background-color: transparent;
    font-family: IBM Plex Mono, monospace !important;
    font-size: 13px !important;
    margin: 0;
    margin-top: -3px;
  }

  .slick-cell.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .slick-row.odd {
    background-color: #f9f9f9;
  }

  .slick-cell.center {
    display: table-cell;
    text-align: center;
  }

  .gt-tp-icon {
    margin-left: auto;
    margin-right: auto;

    width: 20px;
    height: 20px;
    cursor: pointer;

    position: relative;
  }

  .slickgrid-container .slick-header.ui-state-default {
    width: 100% !important;
  }

  .slick-pane-header {
    height: 22px;
  }

  .slick-header-columns {
    > .slick-header-column {
      background-color: rgba(220, 223, 226, 0.5) !important;
      border-right: solid 1px #d4d4d4 !important;
      user-select: none;
      padding: 2px 4px;
      text-align: center;
      height: 22px;
      padding: 0px 2px !important;

      display: flex;
      align-items: center;

      > span.slick-column-name {
        font-family: IBM Plex Mono, monospace;
        font-style: normal;
        font-size: 12px !important;
        line-height: 1.4em;

        width: 100%;

        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: rgba(26, 31, 54, 0.8);

        user-select: none;

        margin: 0;
      }

      .slick-header-menu-button {
        display: none;
      }

      .slick-resizable-handle {
        position: absolute;
        font-size: 0.1px;
        display: block;
        cursor: col-resize;
        width: 9px;
        right: -5px;
        top: 0px;
        height: 100%;
        z-index: 2;
      }
    }

  }

  .slick-header-column.invert {
    background-color: #575757 !important;

    > span.slick-column-name {
      color: #fff;

      font-family: IBM Plex Mono;
      font-size: 11px;
      font-weight: 600;
    }
  }

  .slick-row {
    &:hover {
      background: inherit !important;
    }

    &.odd {
      background: rgba(62, 66, 85, 0.04) !important;
    }
  }

  .slick-cell {
    display: flex;
    align-items: center;

    border-right: solid 1px !important;
    border-bottom: solid 1px !important;
    border-color: #d4d4d4 !important;

    font-family: IBM Plex Mono, monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 1em;
    padding-right: 8px;
    padding-left: 8px;

    color: rgba(15, 18, 32, 1);

    &.selected {
      background: rgba(62, 66, 85, 0.2) !important;
    }
  }

  .slick-cell.qube-point {
    background: rgba(62, 193, 130, 0.3);
  }
  .slick-cell.disabled-point {
    opacity: 0.2;
  }

`;