import MobileDetect from 'mobile-detect';
import ReactGA from "react-ga4";
import {
  createPassage,
  deletePassage,
  launch,
  markTriggerAsSeen,
  toggleActiveTrackingPoint,
  updatePassage,
  updateTrackingPoint
} from "app/services/run.service";
import { getSheetColumns, sheetEvents } from "./tracking-sheet.model";
import { InspectionDispatchType, InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { SettingsContext } from 'app/modules/settings/settings.context.d';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import {
  distanceFormatter,
  etaFormatter,
  eteFormatter,
  passageDateFormatter,
  passageTimeFormatter,
  speedFormatter,
  inclinationFormatter,
  inputTypeFormatter,
  triggersFormatter,
} from './tracking-sheet.formatters';
import { getColumns } from 'app/models/sheet/sheet';
import { AuthType } from 'app/modules/account/account.context.d';
import { coordsFormatter } from 'app/models/sheet/formatters';
import { CustomSlickGrid } from 'app/components/slick-table/slick-table.interfaces';
import GTDataView from 'app/components/slick-table/slick-table.dataview';

 /**
   *
   * @param row
   * @param nextPoint
   * @param prevPoint
   */
 const getMapBounds = (row: any, nextPoint, prevPoint): google.maps.LatLng[] => {
  if (!prevPoint?.geometry?.coordinates[1] || !nextPoint?.geometry?.coordinates[0]) {
    const bound = new google.maps.LatLng({
      lat: row?.geometry?.coordinates[1],
      lng: row?.geometry?.coordinates[0],
    });
    return [bound];
  }
  const bound1 = new google.maps.LatLng({
    lat: row?.geometry?.coordinates[1],
    lng: row?.geometry?.coordinates[0],
  });
  const bound2 = new google.maps.LatLng({
    lat: prevPoint?.geometry?.coordinates[1],
    lng: prevPoint?.geometry?.coordinates[0],
  });
  const bound3 = new google.maps.LatLng({
    lat: nextPoint?.geometry?.coordinates[1],
    lng: nextPoint?.geometry?.coordinates[0],
  });

  const bounds: google.maps.LatLng[] = [
    bound1,
    bound2,
    bound3,
  ];

  return bounds;
};

interface HandleSelectPointProps {
  point: TrackingPointCascadeType,
  nextPoint: TrackingPointCascadeType,
  prevPoint: TrackingPointCascadeType,
  inspectionContextDispatch: InspectionProviderValue['dispatch'],
  setBounds: (data: google.maps.LatLng[]) => void,
}

/**
 *
 * @param oprams
 */
const _handleSelectPoint = ({
  point,
  nextPoint,
  prevPoint,
  inspectionContextDispatch,
  setBounds
}: HandleSelectPointProps) => {
  inspectionContextDispatch({ type: 'SET_SELECTED_POINT', data: point });
  const bounds = getMapBounds(point, nextPoint, prevPoint);
  setBounds(bounds);
};

/**
 *
 * @returns
 */

const handleCommonTextChange = async (
  args: any,
  token: string,
  setError: (data: { title: string; text: string; type: string; }) => void,
) => { // TODO: type event args
  const data = {};
  const point = args.item;
  const column = args.column?.id ? args.column : args.grid.getColumns()[args.cell];
  data[column.field] = point[column.field];

  try {
    await updateTrackingPoint(point.id, data, token);
  } catch {
    setError({ type: 'error', title: 'Error', text: 'Error to update tracking point. Please, try again later.' });
  }
};

export const generateColumns = (
  inspectionContext: InspectionProviderValue,
  settingsState: SettingsContext,
  isObserver: boolean,
  auth: AuthType,
  setBounds: (bounds: google.maps.LatLng[]) => void,
  setError: ({ title, type, text }: {
    title: string,
    text: string
    type: string
  }) => void,
) => {

  const columns = getColumns(
    {
      speed: settingsState.speedUnit,
      distance: settingsState.distanceUnit,
    },
    !isObserver,
    getSheetColumns(),
    [
      ['passage_date(Passage)', 'Passage Date', 150, passageDateFormatter, ['permission_type', 'passage', 'run']],
      ['passage_time(Passage)', 'Passage Time', 150, passageTimeFormatter, ['permission_type', 'passage', 'run']],
      ['speed*', 'Speed', 100, speedFormatter],
      ['speed_delta*', 'Speed Dt.', 100, speedFormatter],
      ['eta*', 'ETA', 160, etaFormatter],
      ['ete*', 'ETE', 160, eteFormatter, ['eta', 'launched', 'is_next']],
      ['distance*', 'Dist. from Previous', 140, distanceFormatter],
      ['longitude*', 'Longitude', 140, coordsFormatter, ['geometry']],
      ['latitude*', 'Latitude', 140, coordsFormatter, ['geometry']],
      ['chainage(Distance)'],
      ['elevation*', 'Elevation', 140, distanceFormatter],
      ['inclination*', 'Inclination', 140, inclinationFormatter],
      ['input_type*', 'Approval', 90, inputTypeFormatter, ['trigger_set', 'passage_type'], 'invert'],
      ['triggers*', 'Triggers', 90, triggersFormatter, ['trigger_set', 'triggers_seen_triggers'], 'invert'],
    ],
    getEvents(inspectionContext, auth, _handleSelectPoint, setBounds, setError),
  );

  return columns;
}

/**
 *
 * @param token
 * @returns
 */
export const flyTo = async (
  args: any,
  handleSelectPoint: (data: any) => void,
  inspectionContextDispatch: InspectionProviderValue['dispatch'],
  setBounds: (bounds: google.maps.LatLng[]) => void,
) => {
  const point = args.grid.getDataItem(args.row);
  const nextPoint = args.grid.getDataItem(args.row + 1);
  const prevPoint = args.grid.getDataItem(args.row - 1);
  handleSelectPoint({ point, nextPoint, prevPoint, inspectionContextDispatch, setBounds  });
};

/**
 *
 * @param args
 * @param token
 */
const handlePassageTimeChange = async (
  args: any,
  token: string,
  setError: (data: {
    title: string,
    text: string
    type: string
  }) => void,
) => {
  const point = args.item;
  const trackingPointId = point.id;
  const runId = point.run;
  const passage = point.passage;
  const distance = point.distance;
  const speed = point.speed;
  const isoDate = args.item.passage_time;

  const data = {
    run: runId,
    distance: distance,
    tracking_point: trackingPointId,
    tstamp: isoDate,
    id: passage?.id,
    speed: undefined
  };

  if (distance === '0') data.speed = speed;

  const launched = args.grid.metadata?.launched;
  if (!launched) {
    if (args.row === 0) {
      try {
        const launchData = {
          id: runId,
          tstamp: data.tstamp,
          speed: args.grid.metadata?.predicted_launch_speed,
          as_test: false,
        };

        await launch(runId, launchData, token);
      } catch (err: any) {
        if (err.response.data.type) {
          return setError({ title: 'Error', type: 'error', text: 'Sorry, error to launch run, try again later or contact us.' });
        }
      }
    } else {
      setError({ title: 'Error', type: 'error', text: 'Sorry, to register a passage, launch the run first.' });
    }

    return;
  }

  if (isoDate && passage.id) {
    try{
      await updatePassage(passage.id, data, token);
    } catch {
      setError({ title: 'Error', type: 'error', text: 'Error to update passage. Please, try again later.' });
    }
  }

  if (isoDate && !passage.id) {
    try{
      await createPassage(data, token);
    } catch {
      setError({ title: 'Error', type: 'error', text: 'Error to create passage. Please, try again later.' });
    }

    ReactGA.event({
      category: "Run",
      action: "Create Passage",
      label: "time",
    });
  }

  if (!isoDate && passage.id) {
    try {
      await deletePassage(passage.id, token)
    } catch {
      setError({ title: 'Error', type: 'error', text: 'Error to delete passage. Please, try again later' });
    }
  }
};

/**
 *
 * @param args
 * @param token
 */
const handlePassageDateChange = async (
  args: any,
  token: string,
  setError: (data: {
    title: string,
    text: string
    type: string
  }) => void,
) => {
  const point = args.item;
  const trackingPointId = point.id;
  const runId = point.run;
  const passage = point.passage;
  const distance = point.distance;
  const speed = point.speed;
  const isoDate = point.passage_date;

  const data = {
    run: runId,
    distance: distance,
    tracking_point: trackingPointId,
    tstamp: isoDate,
    id: passage?.id,
    speed: undefined
  };

  if (distance === '0') data.speed = speed;

  if (isoDate && passage.id) {
    try {
      await updatePassage(passage.id, data, token);
    } catch {
      setError({ title: 'Error', type: 'error', text: 'Error to update passage. Please, try again later' });
    }
  }

  if (isoDate && !passage.id) {
    try {
      await createPassage(data, token);
    } catch {
      setError({ title: 'Error', type: 'error', text: 'Error to create passage. Please, try again later' });
    }

    ReactGA.event({
      category: "Run",
      action: "Create Passage",
      label: "date",
    });
  }

  if (!isoDate && passage.id) {
    try {
      await deletePassage(passage.id, token)
    } catch {
      setError({ title: 'Error', type: 'error', text: 'Error to delete passage. Please, try again later' });
    }
  }
};

/**
 *
 * @param args
 * @param token
 */
const handleTriggersClick = (args: any, inspectionContextDispatch: (dispatch: InspectionDispatchType) => void) => {
  const point = args.grid.getDataItem(args.row);
  const auth = args.grid.metadata?.auth as AuthType;
  const permission_type = args.grid.metadata?.permission_type;

  if (!point?.trigger_set?.length || permission_type !== 'editor' || !auth) return;

  inspectionContextDispatch({
    type: 'SET_SELECTED_POINT_TRIGGERS',
    data: point,
  });

  // TODO: MARK TRIGGERS AS SEEN
  for (const trigger of point.trigger_set) {
    if (trigger.triggerseen_set?.find((tseen) => tseen.user === auth.user.id)) continue;
    markTriggerAsSeen(trigger.id, auth.token);
  }
}

/**
 *
 * @returns
 */
const toggleActive = async (
  args: any,
  token: string,
  setError: (data: {
    title: string,
    text: string
    type: string
  }) => void,
) => {
  const point = args.grid.getDataItem(args.row);

  try {
    await toggleActiveTrackingPoint(
      `${point.id}`,
      {
        id: point.id,
        active: !point.active,
      },
      token
    );
  } catch {
    setError({ title: 'Error', type: 'error', text: 'Error to delete passage. Please, try again later' });
  }
};

/**
 *
 * @param inspectionContextDispatch
 * @param user
 * @param token
 * @param handleSelectPoint
 * @param setTriggerPoint
 * @param setBounds
 * @param setError
 * @returns
 */
export const getEvents = (
  inspectionContext: InspectionProviderValue,
  auth: AuthType,
  handleSelectPoint: (data: any) => void,
  setBounds: (bounds: google.maps.LatLng[]) => void,
  setError: (data: {
    title: string,
    text: string
    type: string
  }) => void,
) => {
  const changeHandles = {
    handleCommonTextChange,
    handlePassageTimeChange,
    handlePassageDateChange,
  };

  const clickHandles = {
    handleTriggersClick: (args: any) => handleTriggersClick(args, inspectionContext.dispatch),
    flyTo: (args) => flyTo(args, handleSelectPoint, inspectionContext.dispatch, setBounds),
    toggleActive,
  };

  const doubleClickHandles = {};
  const events = {};

  Object.keys(sheetEvents).forEach((key) => {
    const event: {
      onChange?: (e: any, args: any) => void;
      onClick?: (e: any, args: any) => void;
      onDoubleClick?: (e: any, args: any) => void;
    } = {};
    if (sheetEvents[key].onChange) {
      event.onChange = (e: any, args: any) => {

        return changeHandles[sheetEvents[key].onChange](args, auth.token, setError, inspectionContext.run);
      }
    }

    if (sheetEvents[key].onClick) {
      event.onClick = (e: any, args: any) =>
        clickHandles[sheetEvents[key].onClick](args, auth.token, setError);
    }

    if (sheetEvents[key].onDoubleClick) {
      event.onDoubleClick = (e: any, args: any) =>
        doubleClickHandles[sheetEvents[key].onDoubleClick](args, auth.token);
    }

    events[key] = event;
  });

  return events;
};

/**
 *
 * @returns
 */
export const getFrozenColumn = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile()) {
    return 0;
  }

  return 3;
};

/**
 * refresh every ETE columns
 * @param grid
 */
export const refreshETE = (grid: CustomSlickGrid<TrackingPointCascadeType>, dataView: GTDataView<TrackingPointCascadeType>) => {
  // probably always there is slickGrid, but is a necessary interface validation
  if (!grid) return;

  const col = grid.getColumnIndex('ete');
  for (let i = 0; i < dataView.getLength(); i++) {
    // ETE column to update
    grid.updateCell(i, col);
  }
};

/**
 *
 * @param deltaY
 * @param container
 * @param grid
 * @returns
 */
export const handleDrag = (deltaY: number, container, grid: any, changeViewMode?: boolean) => { // TODO: GRID Type
  if (changeViewMode) {
    container.style.gridTemplateRows = `auto 1fr 40vh`;
    grid?.resizeCanvas();
    return;
  }

  const h = document.querySelector('#GT_SHEET')?.clientHeight || 0;

  if (h - deltaY < 24 || container.clientHeight - h + deltaY < 150) {
    return;
  }

  container.style.gridTemplateRows = `auto 1fr ${h - deltaY}px`;
  grid?.resizeCanvas();
};
