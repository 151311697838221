// configuration of events to each column
export const sheetEvents = {
  passage_time: { onChange: 'handlePassageTimeChange' },
  passage_date: { onChange: 'handlePassageDateChange' },
  name: { onChange: 'handleCommonTextChange' },
  milepost: { onChange: 'handleCommonTextChange' },
  description: { onChange: 'handleCommonTextChange' },
  comment: { onChange: 'handleCommonTextChange' },
  depth_of_cover: { onChange: 'handleCommonTextChange' },
  alignment_sheet: { onChange: 'handleCommonTextChange' },
  speed_offset: { onChange: 'handleCommonTextChange' },
  device_sn: { onChange: 'handleCommonTextChange' },
  county: { onChange: 'handleCommonTextChange' },
  state: { onChange: 'handleCommonTextChange' },
  country: { onChange: 'handleCommonTextChange' },
  tract: { onChange: 'handleCommonTextChange' },
  pressure_differential: { onChange: 'handleCommonTextChange' },
  location_description: { onChange: 'handleCommonTextChange' },
  antenna_height: { onChange: 'handleCommonTextChange' },
  datum: { onChange: 'handleCommonTextChange' },
  elevation_top: { onChange: 'handleCommonTextChange' },
  ellipsoid_height: { onChange: 'handleCommonTextChange' },
  ellipsoid_height_cop: { onChange: 'handleCommonTextChange' },
  geoid_model: { onChange: 'handleCommonTextChange' },
  survey_accuracy: { onChange: 'handleCommonTextChange' },
  survey_method: { onChange: 'handleCommonTextChange' },
  triggers: { onClick: 'handleTriggersClick' },
  type: { onClick: 'flyTo' },
  active: { onClick: 'toggleActive' },
};

/**
 *
 * @param usingLineStat
 * @returns
 */
export const getSheetColumns = () => {
  const columns = [
    'index',
    'active',
    'type',
    'name',
    'passage_date',
    'passage_time',
    'speed',
    'speed_delta',
    'eta',
    'ete',
    'distance',
    'longitude',
    'latitude',
    'chainage',
    'elevation',
    'inclination',
    'milepost',
    'description',
    'comment',
    'depth_of_cover',
    'alignment_sheet',
    'speed_offset',
    'device_sn',
    'county',
    'state',
    'country',
    'tract',
    'pressure_differential',
    'location_description',
    'antenna_height',
    'datum',
    'elevation_top',
    'ellipsoid_height',
    'ellipsoid_height_cop',
    'geoid_model',
    'survey_accuracy',
    'survey_method',
  ];

  const passageTimeIndex = columns.indexOf('passage_time');
  columns.splice(passageTimeIndex + 1, 0, 'triggers');
  columns.splice(passageTimeIndex + 2, 0, 'input_type');

  return columns;
};
